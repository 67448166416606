
.skeleton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .skeleton-loader .skeleton {
    background: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite;
  }
  
  .skeleton-loader .skeleton.title {
    width: 50%;
    height: 30px;
    /* margin: 0 auto; */
  }
  
  .skeleton-loader .skeleton.input {
    width: 100%;
    height: 40px;
  }
  
  .skeleton-loader .skeleton.button {
    width: 100%;
    height: 40px;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  