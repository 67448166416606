

.ant-layout-sider-zero-width-trigger {
  top: 0 !important;
}

.otpInput {
  width: 40px;
  height: 40px;
  margin: 5px;
  text-align: center;
  font-size: 1.2em;
}